/* .hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
} */

/* .hero:before {
    content: '';
    background: url('./images/hero.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */
/* 
.hero .content {
    position: absolute;
    top: 0;
    left: 17em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.hero .content p:first-child {
    font-style: italic;
    font-size: 5rem;
}

.hero .content p:nth-child(2) {
    font-size: 1.5rem;
} */

/* .hero .button {
    margin: 1rem 0;
    width: 300px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #333;
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
} */
.hero{
    position: relative;
    height: 100vh;
    width: 100%;
    margin: auto;
}
.herobg-video{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}
.hero-content {
    position: absolute;
    top: 40%;
    left: 11%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}
.hero-title{
    font-size: 50px;
}
.hero-description{
    margin-bottom: .5em;
}
.hero-button{
    width: fit-content;
}

@media screen and (max-width:940px) {
    .herobg-video{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background: #232a34;
    }
    .hero{
        height: 100vh;
        width: 100%;
        position: relative;
    } 
    .hero-content {
        position: absolute;
        top: 40%;
        left: 5%;
        width: 90%;
        color: white;
        display: flex;
        flex-direction: column;
    }
    .hero-title{
        font-size: 27px;
    }
    .hero-description{
        margin-bottom: .5em;
        font-size: 13px;
    }
    .hero-button{
        width: fit-content;
        font-size: 10px;
        padding: 8.5px 14px;
    }
}

@media screen and (max-width:400px ) {
    .herobg-video{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background: #232a34;
    }
    .hero{
        height: 100vh;
        width: 100%;
        position: relative;
    } 
    .hero-content {
        position: absolute;
        top: 40%;
        left: 5%;
        width: 90%;
        color: white;
        display: flex;
        flex-direction: column;
    }
    .hero-title{
        font-size: 25px;
    }
    .hero-description{
        margin-bottom: .5em;
        font-size: 13px;
    }
    .hero-button{
        width: fit-content;
        font-size: 10px;
        padding: 8.5px 14px;
    }
}










