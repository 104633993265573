.about {
    background-color: #f8f8f8;
    margin: auto;
    padding: 0rem 1rem 0 1rem;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    
}
.self-image{
    width: 50%;
    margin: auto;
    border-radius: 50%;
}
.about .container {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about h2 {
    font-size: 3rem;
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.description {
    margin: 2rem 0;
    line-height: 1.7;
}

.bold-description {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
}
.about a{
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
}
.resume-button{
    padding: 7px
}
.about-btn {
    padding: 7px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer; 
}
.collapse-btn{
    padding: 17px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer; 
}
.stack-display{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1em;
    box-shadow: 0 0 10px 2px;
    width: 100%;
    padding:.5em 0 .5em 0;
    margin: auto;
    grid-column: 2 / 3;
    margin-top: 1em;
}
.about-btn-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
}

@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
        height: fit-content;
    }

    .about .container {
        width: 100%;
    }

    .self-image {
        width: 90%;
        margin: auto;
    }

    .about .container .col-2 {
        padding: 0;
    }
    .about a{
        font-size: 1.2rem;
        color: #fff;
        font-weight: 600;
    }
    .collapse-btn{
        padding: 15px;
        font-size: 1.2rem;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 4px;
        cursor: pointer; 
    }
    .collapse-btn:hover{
        background: var(--primary-dark);
        border: 1px solid var(--primary-dark);
    }
    .resume-button{
        padding: 17px

    }
    .stack-display{
        /* display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 1em;
        box-shadow: 0 0 10px 2px;
        width: 100%;
        padding:.5em 0 .5em 0;
        margin: auto;
        margin-top: 1em; */
        display: grid;
        grid-template-columns: repeat(3, auto);
        margin-top: 1em;
        align-items: center;
    }
    /* .stack-display img{
        width: 20px;
        height: 20px;
    } */
    .about-btn-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 5px;
        margin-top: 1em;
    }
}









