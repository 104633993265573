.footer {
    width: 100%;
    background-color: #000;
    color: #eee;
    padding: 3rem 1rem;
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.bottom {
    padding-top: 3rem;
    text-align: center;
}
.bottom img{
    background-color: white;
    width: 30px
}

.line {
    margin-bottom: 1rem;
}

@media screen and (max-width: 390px){
    .footer li{
        font-size: small;
    }
    .bottom{
        font-size: 17px;
    }
}




