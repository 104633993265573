.testimonials {
    width: 100%;
}

.testimonials .container {
    width: 90%;
    margin: 4rem auto;
    padding: 4rem 0rem 1rem 0rem;
}

.testimonials h2 {
    font-size: 3rem;
    text-align: center;
}

.testimonials .container .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin: 4rem 0;
}

.testimonials .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
}

.testimonials img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-top: -2rem;
}


.testimonials .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.testimonials .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (max-width: 940px) {
    .testimonials-heading{
        width: 100%;
        font-size: 3rem;
        text-align: center; 
    }

    .testimonials .container {
        width: 100%;
    }

    .testimonials .container .content {
        grid-template-columns: 1fr;
    }

    .testimonials .card {
        max-width: 100%;
        margin: 1rem;
    }
    .testimonials img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        margin-top: -2rem;
    }
}









