.header {
    position: sticky;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgba(0,0,0,.9);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #ffffff;
}

.header h1 {
    width: 250px;
    height: auto;
    color: #fff
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header {
        width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header h1 {
        width: 150px;
    }
}
















